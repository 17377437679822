.divMayor {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.1rem;
}

.carrouselContainer{
    background-color: black;
    display: flex;
    align-self: center;
    margin: 2rem;
    max-width: fit-content;
    max-height: fit-content;
    box-shadow: 1px 1px 4px 4px rgb(255, 255, 255, 1);
    border-radius: 13px 13px 13px 13px;
}
.carrouselContainer h1{
    padding: 1rem;
    color: white;
    font-size: 48px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-decoration: underline;
}

.containerProducts {
    background-color: lightseagreen;
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    grid-template-rows: repeat(3);
    gap: 1rem;
    width: 87vw;
    padding: 0;
    padding-left: 1rem;
    margin-top: 2rem;
    margin-left:0.3rem;
}

.oneProduct {
    display: grid;
    grid-template-columns: repeat(1, 0.2fr);    
    height: 13.8rem;
    width: 13.8rem;
    background-color: white;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 25px 25px 25px 25px;
    padding: 1rem;
    justify-content: center;
    transition: box-shadow 0.3s ease;

}
.oneProduct:hover {
    box-shadow: 0px 5px 9px rgb(240, 228, 228, 2); 
}
.oneProduct label{
    width: fit-content;
    align-self: center;
    justify-self: center;
}


.imageContainer{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 15rem;
    height: 10rem;
    overflow: hidden;
}

.Showimg {
    justify-content: center;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 25px 25px 25px 25px;
    cursor: pointer; 
    transition: transform 0.3s ease; 
}

.filterDiv{
    display: flex;
    align-content: center;
    justify-content: center;
}

.filterDiv select{
    margin-left: 1rem;
    margin-right: 1rem;
}

.filterDiv button{
    margin-right: 1rem;
    margin-left: 1rem;
    cursor: pointer;

}

.filterDiv button:hover {
    background-color: green;
    color: white;
    box-shadow: 1px 1px 1px 1px rgb(0,0,0,2);
}

@media screen and (device-width <= 768px) and (orientation: portrait){
    .divMayor{
        margin: 0;
    }
    .cartNoInclude{
        display: flex;
        margin: 0;
        justify-content: none;
    }
    .filterDiv{
        display: flex;
        flex-direction: column;
        width: 80vw;
        margin-top: 2rem;
        align-self: center;
    }
    .filterDiv button{
        margin-right: 1rem;
        margin-left: 1rem;
        border-radius: 15px;
        align-self: center;
        width: fit-content;
        padding: 0.5rem;
        cursor: pointer;
    
    }    
    
    .filterDiv select{
        margin-bottom: 1rem;
        width: 80vw;
        color: rgb(0, 0, 0);
    }
    .filterDiv option{
        color: white;
        background-color: black;
    }
    
    
    .containerProducts{
        display: grid !important;
        grid-template-columns: repeat(3,0fr) ;
        grid-template-rows: repeat(3, 0fr) !important;
        margin:0 ;
    }
    .oneProduct{
        width: 20vw;
        height: auto;
    }
    .imageContainer{
        width: 20vw;
        height: 20vw;
    }
    .Showimg{
        width: 20vw;
        height: 20vw;
    }
    
}