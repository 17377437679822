.divMayor {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100vh; 
    width: 90vw;
    background-color: none; 
}

.containerAll {
    display: flex;
    width: 50vw;
    height: fit-content;
    -ms-flex-align: center;
    background-color: white;
    padding: 1rem;
    border-radius: 25px 25px 25px 25px;
    box-shadow: -2px -2px 3px 3px rgba(0, 0, 0, 0.8);
}

.containerImg{
    display: grid;
    grid-template-columns: repeat(1, auto);
    position: relative;
    width: 25rem;
    height: 30rem; 
    overflow: hidden;   
}

.oneImg{
    display: flex;
    max-width:30rem;
    max-height: 30rem;
    cursor: zoom-in;
}

.showImg{
    object-fit: auto;
    width: 20rem;
    height: 20rem;
    border: 1px solid black;
    cursor: zoom-in;
    
}

.buttonandCarrousel{
    display: flex;
    width: 20rem;
    height: 20rem;
   
}

.nextButton {
    z-index: 1;
    color: rgb(255, 255, 255);
    position: absolute; 
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    cursor: pointer;
    font-size: 12px;
    right: 4.85rem;
}
.prevButton{
    z-index: 1;
    color: white;
    position: absolute;    
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    cursor: pointer;
    font-size: 12px;
}
.divWhatsapp{
    display: flex;
}

.divWhatsapp img {
    object-fit: scale-down;
    margin-bottom: 5rem;
}
.divWhatsapp a{
    margin-top: 1.5rem;
}

.details{
    display: grid;
    grid-template-columns: repeat(1,0.2fr);
    width: fit-content;
    height:fit-content;
}
.details h3{
    width: fit-content;
    height: fit-content;
    text-align: center;
    text-shadow: 3px 3px 3px 3px rgb(78, 78, 204, 2);

    text-decoration: underline;
    font-size: 32px;
}
.details p{
    width: fit-content;
    height: fit-content;
    font-size: 18px;

}
.details label{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.details select{
    width: fit-content;
    background-color: black;
    color: white;
}

.addbutton{
    margin-left: 2rem;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    max-width: fit-content;
    max-height: 3rem;
    border-radius: 25px 25px 25px 25px;
    cursor: pointer;
}

.addbutton:hover{
    background-color: green;
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
}

.backButton{
    background-color: rgb(228, 201, 201);
    color: blue;
    border-radius: 50px 50px 50px 50px;
    cursor: pointer;
    margin: 2rem;
    width: 4.5rem;
    height: 4.5rem;
}
.backButton:hover{
    box-shadow: 3px 3px 8px 8px rgba(0, 0, 0, 0.8);
    background-color: black;
    color: white;
}
.zoomModal {
    margin-top: 2rem;
    width: 90vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000; 
    
}
.zoomModal:hover{
    cursor: zoom-out;
}

.zoomedImage {
    margin-top: 5rem;
    transition: transform 0.3s ease;
    max-width: 90vw; 
    max-height: 80vh; 
    object-fit: cover; 
}

.zoomedImageContainer {
    max-width: 90vw; 
    max-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zoomedImage:hover {
    cursor: zoom-out; 
}

@media screen and (max-device-width: 768px) and (orientation: portrait){
    .divMayor{
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
        width: 100vw;
        height: 100vh;
    }    
    .oneImg{
        width: 120px;
        height: 120px;
    }
    .showImg{
        width: 120px;
        height: 120px;
    }
    .containerImg{
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
    }
    .divWhatsapp{
        margin-top: 2rem;
    }
    .containerAll{
        width: fit-content;
        height: fit-content;
    }
    .buttonandCarrousel{
        display: flex;
        flex-direction: row;
        width: fit-content;
        height: fit-content;
    }
    .nextButton{
        position: relative;
        right: 0;
    }
    .prevButton{
        position: relative;
        left: 0;
    }
    
}