.divMayor{
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 80vh;
}
.HeadContainer {
    background-color: none;
    justify-self: center;
    align-self: center;
}
.HeadContainer h1{
    color: white;
    font-size: 50px;
    text-decoration: underline;
    font-family:'Courier New', Courier, monospace
}

.gridContainer{    
    display: grid;
    grid-template-columns: repeat(1,1fr);
    background-color: none;
    margin-top: 5rem;
    row-gap: 0rem;
    
}
.picContainer{
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    background-color: blue;
    border: white solid 1px;
    box-shadow: 2px 2px 0px 0px rgb(0,0,0, 2) ;
    width: 80vw;    
    height: fit-content;
}
.picContainer img{
    width: 20rem;
    height: 20rem;
}
.picContainer p{
    margin-left: 4rem;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
}
.carousel{
    display:contents;
    width: 40rem;
    max-height: 20rem;
    margin-top: 0;
}
.Showimg{
    object-fit:cover;
    max-width: 90vw;
    max-height: 30%;
    margin-top: 0;
    
    
}

.loadingContainer {
    align-content: center;
    justify-content: center;
    padding: 20px;
    margin-left: 40%;
}

.loadingSpinner {
    border: 4px solid rgba(255, 255, 255, 0.6);
    margin-left: 13%;
    margin-top: 10%;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    transform: translate(-50%, -50%);
}
.loadingContainer p{
    display: flex;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (device-width <= 768px) and (orientation: portrait){
    .divMayor{
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
        width: 90vw;
        height: fit-content;
    }
    .gridContainer{
        width: 100vw;
    }
    .picContainer{
        margin: 0;
        width: 100vw;
        height: fit-content;
        background-color: lightgreen;
    }
    .carousel{
        width: 120px;
        height: 120px;
    }
    .picContainer img{
        width: 120px;
        height: 120px;
    }
    .picContainer p{
        margin: 0;
        margin-left: 1rem;
        text-decoration:dotted;
        
    }
}