.divMayor{
    display: flex;
    flex-direction: column;
    width: 90vw;
}
.imgContainer{
    width: 90vw;
    height: 40vh;
}
.imgContainer img{
    width: 90vw;
    height: 40vh;
}
.textContainer{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    justify-self: center;
    background-color: white;
    width: 87vw;
    height: 100%;
}
.textContainer h2{
    color: black;
}
.textContainer p{
    color: black;
}

@media screen and (max-device-width: 768px) and (orientation: portrait){
    .divMayor{
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
    .textContainer{
        margin-top: 5rem;
        width: 93vw;
        align-self: center;
    }
    .imgContainer{
        width: 100vw;
    }
    .imgContainer img{
        margin-top: 5rem;
        width: 100vw;
    }

}