.divMayor{
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 80vh;
    overflow-y: scroll;
}

.titlesContainer {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    background-color: rgb(121, 61, 61);
    color: white;
    font-weight: bold;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
}
.titlesContainer label{
    margin-left: 3rem;
    margin-right: 3rem;
}

.OneDetail{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    background-color: white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid black;
}

.OneDetail label{
    margin-left: 3rem;
    margin-right: 3rem;
}
.TotalContainer {
    display: flex;
    justify-content: space-between; 
    background-color: antiquewhite;
}

.totalLabel {
    align-self: flex-start; 
    margin-left: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.totalSum {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    align-self: flex-end;
    margin-right: 15rem;
}

.buttonExcel{
    align-self: center;
    justify-self: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
}
.buttonExcel:hover{
    box-shadow: 1px 1px 1px 1px rgb(255,255,255,1);
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .divMayor{
        width: 100vw;
        height: 35vh;
    }
    
}