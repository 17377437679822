.divMayor{
    display: flex;
    width: 87vw;
    flex-direction: column;
    height: max-content;
    background-color: white;
}
.filterDiv{
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-content: center;
    justify-content: center;
}

.filterDiv select{
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: black;
    color: white;
}
.filterDiv button{
    background-color: black;
    color: white;
    height: fit-content;
    width: fit-content;
    padding: 0.5rem;
    cursor: pointer;
}
.filterDiv button:hover{
    box-shadow: 3px 3px 3px 3px rgba(25, 25, 153, 1);
}
.titleContainer{
    margin-top: 1rem;
    margin-bottom:1rem;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    background-color: white;
}
.titleContainer > * {
    border-right: 1px solid #000;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0.5rem; 
}
.titleContainer > :last-child {
    border-right: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}


.dataProduct{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    height: fit-content;
    margin-left: 2rem;
    border-bottom: 1px solid black;
    justify-items: center;
    
}
.divImg{
    display: flex;    
}

.divImg img{
    width: 80px;
    height: 80px;
}

@media screen and (max-device-width: 768px) and (orientation: portrait){
    .divMayor{
        width: 95vw;
        height: 20vh;
        margin: 0;
        padding-bottom: 1rem;
    }
    .filterDiv{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 85vw;
        height: 30vh;
        
    }
    .filterDiv select{
        width: 80vw;
    }
    .divImg{
        display: flex; 
        margin-left: 0;   
    }
    
    .divImg img{
        width: 40px;
        height: 40px;
    }
    .titleContainer{
        width: 95vw;
        margin: 0;
        align-self: center;
    }
    .dataProduct{
        background-color: white;
        width: 95vw;
        margin: 0;
        padding-top: 1rem;
    }
}
