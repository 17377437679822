.divMayor{
    display: flex;
    height: 100vh;
    width: 90vw;
    -ms-flex-align: center;
    justify-content: center;    
}

.buttonContainer{
    margin-top: 1rem;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    height: fit-content;
    width: 80vw;
    justify-content: center;
}

.optionsContainer{
    align-content: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

}
.optionsContainer button{
    background-color: black;
    color:white;
    cursor: pointer;
    height: 3rem;
    width: 6rem;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 1px 1px 1px 1px rgb(255,255,255, 1);
}

.optionsContainer button:hover{
    background-color: blue;
    box-shadow: 3px 0 4px rgb(255,255,255, 4), -3px 0 4px rgb(255,255,255, 4);
}

.inputContainer{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 20rem;    
}

.inputContainer input{
    margin-left: 0.5rem;
    width: 10rem;

}

.inputContainer span{
    margin-left: 0.5rem;
}

.password{
    display: grid;
    grid-template-columns: repeat(2,0.5fr);
}
.password input{
    margin-left: 0.5rem;
}

.singupbuttons {
   max-width: 20rem;
   margin: 0.5rem;
}

.singupbuttons button {
    cursor: pointer;
    margin-left: 2rem;
    background-color: aqua;
    border-radius: 25px 25px 25px 25px;

}

.singupbuttons button:hover{
    background-color: black;    
    color: white;
}

.eyes{
    cursor: pointer;
    color: black;
}

.logginDiv{
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;  
    align-self: center;
    padding: 1rem; 
    color: black;
    background-color: white;
    border: 3px solid black;
    
    
}

@media screen and (max-device-width: 768px) and (orientation: portrait){
    .logginDiv{
        align-content: center;
        width: 100vw;
        padding-left: 25%;
    }
    .divMayor{
        align-items: center;
        width: 100vw;
        margin-top: 3rem;
    }
    .inputContainer{
        display: flex;
        flex-direction: column;
        
    }
    .buttonContainer{        
        display: grid;
        grid-template-columns: repeat(3,1fr);
        margin-left: 1.5rem;
    }
    
}