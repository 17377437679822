.divMayor {
    display: flex;
    justify-content: center;
    width: 80vw;
    height: 80vh;
}

.form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-self: center;
    background-color: white;
    height: 20rem;
    width: 50rem;
    padding: 1.5rem;
}

.divLabel {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}


.form h2 {
    color: black;
    margin-bottom: 1.5rem;
    justify-self: center;
    text-align: center;
}


.divMayor input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.error {
    color: red;
    margin-left: 1rem;
}

.form button {
    width: 5rem;
    height: 3rem;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 25px 25px 25px 25px;
    background-color: Black;
    color: white;
    justify-self: center;
}

.form button:hover {
    width: 5rem;
    height: 3rem;
    justify-self: center;
    background-color: green;
    color: white;
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0, 2);
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .divMayor {
        display: flex;
        justify-content: center !important;
        height: 80vh !important;
        width: 100vh !important;
    }
}