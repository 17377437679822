.divMayor{
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 80vh;  
    overflow-y: auto;
}

.divTitles {
    display: flex;
    background-color: rgb(36, 165, 47);
    justify-content: space-between;
    width: 80vw;
    height: fit-content;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.divTitles label{
    margin-left: 4rem;
    margin-right: 5rem;
    color: white;
}

.oneBooking{
    display: flex;   
    flex-direction: column;
}

.notBookings p{
    font-family: Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;

}


.detailoneBooking{
    display: grid;
    grid-template-columns: repeat(6, 0.5fr);
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    border-top: 1px solid black;   
    border-bottom: 1px dashed black;  
}

.detailoneBooking p{
    align-self: center;
    justify-self: center;
}
.iconX{
    align-self: center;
    justify-self: center;
    color: red;
    box-shadow: 1px 1px 1px 1px rgb(0, 0, 0 , 2);
    cursor: pointer;
}


@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .divMayor{
        width: 100vw;
        height: 35vh;
        overflow-y: auto;
    }
    .divTitles{
        position: absolute;
        padding-left: 0.5rem;
        width: 100vw;
        justify-content: space-between;
    }
    .divTitles label{
        margin:0;
    }
    .oneBooking{
        width: 100vw;
    }
    .detailoneBooking{
        width: 100vw;
        padding-left: 0.4rem;
        height: 40vh;
    }
}

