.divMayor{
    display: flex;
    width: 90vw;
    background-color: white;
    
}

.divContainer{
    display: flexbox;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.carousel{
    display:flex;
    justify-content: center;   
    width: 90vw;
    height: 20rem;
    margin-top: 0;
    background-color: rgba(78, 78, 236, 0.822);
}

.Showimg{
    object-fit:cover;
    justify-items: center;
    max-width: 90vw;
    max-height: 20rem;
    margin-top: 0;
    display: none;
    transform: translateX(100%);
}
  
.Showimg:first-child {
    display: block;
    transform: translateX(0);
}
  

.AboutContainer {
    display: flex;
    flex-direction: row;
}

.contentData{
    display: flexbox;
    width: 70%;
    margin-top: 1rem;
    border: 1px solid black;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
}
.ubicacionContainer{
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    align-items: center;
    margin: 0;
    margin-top: 1rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    padding-left: 1rem;
    padding-right: 1rem;
   
}
.ubicacionDiv{
    display: flex;
    flex-direction: column;
    width: 15rem;
    border-bottom: solid black 1px;
    align-content: center;
    padding-bottom: 1rem;
}
.ubicacionContainer h1{
    text-decoration: underline;
}
.ubicacionDiv:last-child{
    border-bottom: none;
}
.ubicacionContainer p {
    padding: 0;
    padding-top: 1rem;
    margin: 0;
}
.whatsapp{
    align-self: flex-start;
}
.whatsapp img{
    width: 30px;
    height: 30px;
}
.whatsapp img:hover{
    cursor: pointer;
}


.AboutContainer p {
    color: rgb(0, 0, 0);
    font-style: normal;
    font-family:'Times New Roman', Times, serif;
}
.showImg{
    margin: 0;
    margin-top: 1rem;
    align-self: center;
    width: 250px;
    height: 180px;
}
.defaultButton{
    background-color:  rgb(45,64,243);   
    margin-top: 1rem;
    position: relative;
    left:  -2rem;
    color: white; 
    text-decoration: dotted;
    font-size: 20px;
    width: fit-content;
}
.defaultButton:hover{
    cursor: pointer;
    background-color: white;
    color: black;
}

@media screen and (max-device-width: 768px) and (orientation: portrait){
    .divMayor{
        display: flex;
        flex-direction: column;
        width: 100vw; 
        margin: 0
    }
    .divContainer{
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 100vw;
    }
    .AboutContainer{
        display: flex;
        background-color: white;
        width: 100vw;
        flex-direction: column;
    }
    
    .contentData{
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 90vw;
        margin: 0;
    }
    .Showimg{
        margin-top: 5.3rem;
        overflow-y: hidden;
        width: 100vw;
        max-height: 100px;
    }

    .carousel{
        margin: 0;
        width: 87vw;
        margin-left: 1rem;
    }

    .ubicacionContainer{
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 90vw;   
        margin: 0; 
    }
    .ubicacionDiv{
        width: 90vw;
    }
    .ubicacionDiv img{
        width: 100%;
    }
    .defaultButton{
        background-color:  rgb(45,64,243);   
        margin-top: 1rem;
        position: relative;
        align-self: center;
        color: white; 
        text-decoration: dotted;
        font-size: 20px;
        width: fit-content;
    }   
   
}