.divMayor {
    display:grid;
    grid-template-columns: repeat(1, 0.2fr);
    grid-template-rows: 0.1fr;
    justify-content: center;
    background-color: none;
    height: 90vh;
    width: 80vw;
    margin: 0;
}

.h1container {
    margin-top: 0;
    justify-self: center;
    height: fit-content;
    margin-right: 4rem;
    margin-left: 1%;
    
}

.h1container h1 {
    color: white;
    justify-self: center;
    height: fit-content;
}


.form {
    display:flexbox;
    color: white;
    justify-content: center;
    margin-top: 0%;
    margin-right: 1%;
    width: fit-content;
    height: fit-content;
    
}
.divForm{
    margin: 0;
}

.divMayor textarea {
    width: 600px;
    height: 200px;
    resize: none;
    overflow-y: auto;
}

.form button{
    border-radius: 25px 25px 25px 25px;
    font-size: larger;
    cursor: pointer;
    background-color: white;
    padding: 0.5rem;
}

.form button:hover{
    background-color: blue;
    color: white;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8);
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .form input{
        color: white !important;
    }
    .divMayor textarea{
        color: white !important;
        overflow-y: auto;
    }
}