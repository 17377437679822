.divMayor{
    display: relative;
    justify-items: center;
    align-items: center;
    width: 80vw;
    height: 100vh;
    padding-bottom: 1rem;
    margin-top: 2rem;
    overflow-y: scroll;
}

.form{    
    background-color: white;
    padding: 1rem;
}

.imagesContainer{
    display: flex; 
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem ;
    border: 1px dashed black;
    border-radius: 10px 10px 10px 10px;
    margin-top: 1rem;
}

.imageItem{
    display: grid;
    grid-template-columns: repeat(4,3fr);    
    border: 0.5px solid black;
    margin-left: 1rem;
}

.imageItem img{
    cursor: pointer;
    width: 150px;
    height: 150px;
}

.imageLabel{
    margin: 1rem;
    background-color: black;
    color: white;
    font-size: 18px;
    font-family: sans-serif;
    font-style: italic;
}

.socialContainer {
    display: flex;
    margin-top: 1rem;
    width: 300px;
}

.socialContainer label {
    background-color: black;
    color: white;
    margin-right: 1rem;
    left: 0;
    margin-bottom: 1rem;
    padding: 0.5rem;
}

.socialContainer input{
    margin-left: 2rem;
    margin-right: 1rem;
    width: auto;
    height: fit-content;
}

.dataContainer input{
    margin-left: 2rem;
    margin-right: 1rem;
    width: auto;
    height: fit-content;
}



.dataContainer {
    display: flex;
    margin-top: 1rem;
    width: 300px;
}

.dataContainer label {
    display: flex;
    background-color: black;
    color: white;
    width: 100px;
    padding: 0.5rem;
    margin-right: 1rem;
    width: fit-content;
    left: 0;
    margin-bottom: 1rem;
}

.uploadContainer{
    display: flex;
    
}
.iconUpload {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: white;
    color: gray;
    border: 1px solid black;
    height: 150px;
    width: 150px;
    align-items: center;
    justify-content: center;
}

.iconUpload span{
    font-size: 40px;
}

.iconUpload span:last-child{
    font-size: 12px;
}


.uploadContainer input {
    display: none;
}

.backAndUpdate{
    height: 30px;
    width: 100px;
    font-style: oblique;
    font-weight: bold;
    border-radius: 15px 15px 15px 15px;
    margin-left: 2rem;
    margin-right: 2rem;
    cursor: pointer;

}
.buttonDelete {
    height: 40px;
    width: 130px;
    font-style: oblique;
    font-weight: bold;
    border-radius: 15px 15px 15px 15px;
    margin-left: 2rem;
    margin-right: 2rem;
    cursor: pointer;
}
.buttonDelete:hover{
    background-color: red;
    color: white;
    font-size: 20px;
    height: 50px;
    width: 160px;
    box-shadow: 3px 3px 3px 3px rgb(0,0,0,2);
}
.backAndUpdate:hover{
    background-color: yellowgreen;
    color: brown;
    box-shadow: 3px 3px 3px 3px rgb(0,0,0,2);
}

.iconX{
    background-color: black;
    color: white;
    cursor: pointer;
    margin-left: -0.7rem;
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .divMayor{
        width: 100vh;
        justify-items: start;
    }
    .containerAllDetails{
        width: 100vh;
        margin-left: 0;
        margin-bottom: 3rem;
    }
}
