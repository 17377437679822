.divMayor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 80vw;
  height: 100vh;
}

.formDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 25px 25px 25px 25px;
}

.form {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.imagesContainer {
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.imagesContainer img {
  cursor: pointer;
  width: 150px;
  height: 150px;
}

.imageLabel {
  margin: 1rem;
  font-size: 18px;
  font-family: sans-serif;
  font-style: italic;
}

.iconUpload {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: white;
  color: gray;
  border: 1px solid black;
  height: 150px;
  width: 150px;
  align-items: center;
  justify-content: center;
}

.iconUpload span {
  font-size: 40px;
}

.iconUpload span:last-child {
  font-size: 12px;
}
.inputUpload {
  display: none;
}

.ImagesDiv {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px dashed black;
  border-radius: 10px 10px 10px 10px;
}

.CreateButton {
  margin-top: 1rem;
  margin-left: 2rem;
  width: 145px;
  height: 55px;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
}
.CreateButton:hover {
  box-shadow: 2px 2px 2px 2px rgb(0, 0, 0, 2);
}
.inputContainers textarea {
  width: 742px;
  height: 200px;
  resize: none;
  overflow-y: scroll;
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
  .divMayor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 80vw;
    height: 20vh;
  }
  .formDiv {
    display: flex;
    flex-direction: column;
    margin-left: 1.4rem;
    background-color: white;
    width: 100vw;
    border-radius: 25px 25px 25px 25px;
    padding-bottom: 2rem ;
  }
  .form{
    text-align: center;
    width: 100vw;
    margin: 0;
  }
  
  .inputContainers {
    width: 100vw;
  }
  .inputContainers textarea {
    width: 90vw;
    overflow-y: scroll;
  }
  .imagesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-self: center;
    width: fit-content;
    margin: 0;
    margin-left: 1.5rem;
    padding-top: 1rem;
  }
  .ImagesDiv{
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    align-content: center;
    margin-left: 1rem;
    width: 92vw;
  }
  .imageLabel{
    align-self: center;
  }
  .iconUpload{
    margin-bottom: 1rem;
  }
}
