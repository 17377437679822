.divMayor{
    display:flex;
    flex-direction: column;
    background-color:  rgb(45,64,243);
    height: 100%;
    padding-bottom: 3rem;
    top: 0;
    width: 10vw;
    bottom: 0;
    border-right: 1px solid black;    
}

.mobileNav{
    display: flex;
    position: fixed;
    z-index: 9999;
    background-color: rgb(45,64,243);
    top: 0;
    width: 100vw;
    height: fit-content;
    justify-content: space-between;
    border-bottom: 0.2rem black solid
}
.MenuIcon{
    margin-left: 1rem;
    width: 3rem;
    height: 3rem;
    align-self: center;
}
.mobileNav h1{
    align-self: center;
    size: 2rem;
    font-style: italic;
}
.MobileLogo{
    width: 5rem;
    height: 5rem;
    align-self: flex-end;
    margin-right: 1rem;
}
.mobileMenu {
    display: flex;
    margin-top: 5rem;
    position: fixed;
    width: 97vw;
    background-color: lightgray;
    border: solid black;
    z-index: 9999;
}
.mobileMenu li{
    list-style-type: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.divImg {
    margin-top: 0;
    display: flex;
    width: 100%;
    height: fit-content;
    
}
.divImg img {
    object-fit:cover;
    top: 0;
    width: 100%;
    height: 100%;   
}
.buttonContainer {
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    -ms-flex-item-align: center;
    display: flex;
    flex-direction: column;
    width: 80%;
}
.buttonContainer button{
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    padding: 5px;
    width: 100%;
    color: black;
    text-decoration: dotted;
    background-color: white;
    border: solid 1px black;
    border-radius: 15px 15px 15px 15px;
   
}
.buttonContainer button:hover{
    box-shadow: 3px 3px 3px 3px rgba(7,49,77,1);
    background-color: green;
    color: white;
}

.defaultButton {
    cursor: pointer;

}

.buttonuser{
    cursor: pointer;
    
}
.login{
    margin-top: 1rem;
    width: 7rem;
    display: flex;
    flex-direction: column;
}
.inputLogin{
    width: 6rem;
    

}
.inputLogin input{
    width: 6rem;
}
.loginButtons{
    display: flex;
    flex-direction: column;
    left: 2rem;
}

.loginButtons button{
    cursor: pointer;
    margin-left: 0rem;
    background-color: aqua;
    border-radius: 25px 25px 25px 25px;
}

.dropdown {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 8rem;   
    padding: 1rem; 
    position: absolute;
    color: black;
    background-color: white;
    margin-left: 8rem;
    bottom: -5.5rem;
    border: 3px solid black;
    z-index: 9999;
}

.inputContainer{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 20rem;    
}

.inputContainer input{
    margin-left: 0.5rem;
    width: 10rem;

}

.inputContainer span{
    margin-left: 0.5rem;
}

.password{
    display: grid;
    grid-template-columns: repeat(2,0.5fr);
}
.password input{
    margin-left: 0.5rem;
}

.singupbuttons {
   max-width: 20rem;
   margin: 0.5rem;
}

.singupbuttons button {
    cursor: pointer;
    margin-left: 2rem;
    background-color: aqua;
    border-radius: 25px 25px 25px 25px;

}

.singupbuttons button:hover{
    background-color: black;    
    color: white;
}

.eyes{
    cursor: pointer;
    color: black;
}

.dropdownMenu {
    display: flex;
    width: fit-content;
    height: fit-content;
    color: black;
    background-color: white;
    position:relative;
    bottom: 10%;
    left: 100%;
    border: 3px solid black;
    border-radius: 0px 0px 20px 20px;
    z-index: 9999;
}

.dropdownMenu ul{
    display: flex;
    margin-right: 2rem;
    justify-self: center;
    height: fit-content;
}

.buttonsMenu {
    cursor: pointer;
    margin-right: 0.5rem;   
    margin-left: 0.5rem;
    border-radius: 18px 18px 18px 18px; 
}
.buttonsMenu:hover{
    box-shadow: 3px 3px 3px 3px rgba(7,49,77,1);
}

.socialContainer {
    display: flex;
    height: fit-content;
    justify-items: center;
    flex-direction: row;
    margin-left: 6rem;
    margin-right: 6rem;
    margin-top: 1rem;
}
.socialContainer img{
    width: 50px;
    height: 50px;
}

.socialContainer a {
    color: white;
    padding-top: 0.7rem;
}

