.divMayor{
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
}

.buttons {
    margin: 2rem;    
    display: inline-block;
    width: fit-content;
    height: fit-content;
    justify-self: center;
}

.buttons span{
    color:  white;
}

.buttons button{
    cursor: pointer;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.buttons button:hover{
    background-color: blue;
    color: white;
    border-radius: 25px 25px 25px 25px;
}

.currentPage{
    width: 30px;
    height: 30px;
    background-color: black;
    color: white;
    border-radius: 25px 25px 25px 25px;
}