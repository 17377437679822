.divMayor{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 86vw;
}

.buttonContainer{
    display: flex;
    top: 0;
    width: fit-content;
    height: fit-content;
    background-color: rgb(0, 0 ,0 , 0.5);
}

.optionsContainer{
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    height: fit-content;
    width: fit-content;
    padding: 1rem;
}

.optionsContainer button{
    background-color: white;
    border-radius: 15px 15px 15px 15px;
    width: 6rem;
    height: 3rem;
    cursor:pointer;   
}
.optionsContainer button:hover{
    box-shadow: 2px 2px 2px 2px rgb(255,255,255, 2);
    background-color: black;
    color: white;
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .divMayor{
        width: 100vh;
    }
}

