.divMayor{
    display: flex;
    width: 100vw;
}

@media screen and (max-device-width: 768px) and (orientation: portrait){
    .divMayor {
        display: flex;
        width: fit-content;
        height: 100vh;
        
    }
  }